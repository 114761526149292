alert = {
  settings: {
    dialog: $('.js-alert'),
    button: $('.js-alert-close')
  },
  init: function(){
    alert.settings.button.click(function(e){
      e.preventDefault();
      $(this).closest(alert.settings.dialog).remove();
    })
  }
}